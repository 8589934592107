import React, { useEffect } from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { getCurrentProduct } from 'lib/CheckProduct'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_KEY,
  enabledReleaseStages: ['staging', 'production'],
  plugins: [new BugsnagPluginReact(React)],
  releaseStage: import.meta.env.VITE_ENV,
  metadata: {
    product: {
      product: getCurrentProduct()
    }
  }
})

const ErrorBoundary = Bugsnag.getPlugin('react')

function BugsnagErrorHandler({ currentAccount, children }) {
  useEffect(() => {
    if (currentAccount?.currentAccount) {
      Bugsnag.setUser(
        currentAccount?.currentAccount?.id,
        currentAccount?.currentAccount?.email,
        currentAccount?.currentAccount?.fullName
      )
    }
  }, [currentAccount?.currentAccount])

  return <ErrorBoundary>{children}</ErrorBoundary>
}

export default BugsnagErrorHandler
