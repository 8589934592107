import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { createUseStyles } from 'react-jss'

import resolveImage from 'lib/resolveImage'
import rgba from 'lib/rgba'
import TextInput from 'components/inputs/TextInput'

const authTextInputHeight = 70
const iconWrapperWidth = 50

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  authInput: ({ input, type }) => ({
    fontWeight: 600,
    lineHeight: 1.5,
    padding: type === 'textarea' ? [ 25, 45, 15, 50 ] : [ 0, 45, 0, 50 ],
    height: type === 'textarea' ? '100%' : authTextInputHeight,
    width: '100%',

    '&:hover, &:focus': {
      boxShadow: input.value ? 'none' : `0px 20px 40px ${rgba(colors.primary, 0.46)}`
    }
  }),
  iconWrapper: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    transform: 'translateX(-50%)',
    height: authTextInputHeight,
    width: iconWrapperWidth,
    zIndex: zIndexes.inputIcon
  },
  icon: {
    filter: `drop-shadow(0px 10px 10px ${rgba(colors.primary, 0.46)})`,
    maxWidth: '100%'
  }
}))

function AuthTextInput({ icon, ...props }) {
  const classes = useStyles(props)

  const prepend = icon && (
    <div className={classes.iconWrapper}>
      <img className={classes.icon} src={resolveImage(`${icon}.png`)} alt={`${icon}.png`} />
    </div>
  )

  return (
    <Fragment>
      {prepend}
      <TextInput className={classes.authInput} fontSize="small" {...props} />
    </Fragment>
  )
}

AuthTextInput.propTypes = {
  icon: PropTypes.string
}

AuthTextInput.defaultProps = {
  icon: null
}

export default AuthTextInput
